var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('hr',{staticClass:"mb-0"}),_c('div',{staticClass:"section-title"},[_c('div',[_c('p',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.type)))])])])]),_c('div',{staticClass:"products"},[_c('VueSlickCarousel',_vm._b({attrs:{"responsive":[
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 425,
          settings: {
            slidesToShow: 1,
          },
        } ]}},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.products),function(product,index){return _c('div',{key:index},[_c('product-home',{attrs:{"product":product}})],1)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }