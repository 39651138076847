<template>
  <div class="container">
    <hr class="mb-0" />

    <div class="section-title">
      <div>
        <p class="title">
          <span>{{ $t(type) }}</span>
        </p>
      </div>

      <!-- <router-link class="link" to="">See All Products</router-link>  -->
    </div>

    <div class="products">
      <VueSlickCarousel
        v-bind="settings"
        :responsive="[
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 425,
            settings: {
              slidesToShow: 1,
            },
          },
        ]"
      >
        <div v-for="(product, index) in products" :key="index">
          <product-home :product="product"></product-home>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import ProductHome from "@/esf_weert_mobilesupplies/core/components/elements/products/ProductHome";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: {
    ProductHome,
    VueSlickCarousel,
  },
  props: {
    products: Array,
    type: String,
  },
  data: function () {
    return {
      settings: {
        // dots: true,
        arrows: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    };
  },
  mounted() {
    console.log(this.products, "Product in Product-Home Component");
  },
};
</script>
